import React from "react";
import Link from "gatsby-link";
import Scaffold from "../../../components/portfolio";

import bookIcon from "../../../images/book.svg";
import engineIcon from "../../../images/engineIcon.png";
import funnelIcon from "../../../images/funnel.svg";

const Clarity = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Clarity Coach</h2>

    <h3>Development Overview</h3>

    <p>
      As the founder of Clarity Coach, I am responsible for every aspect of the
      business. I split development into three sections to help with project
      planning and time management.
    </p>

    <h4
      style={{
        borderBottom: "4px solid #0074D9",
        paddingBottom: 2,
        display: "inline-block",
      }}
    >
      The engine - System Architecture and Design
    </h4>
    <div style={{ display: "flex" }}>
      <img
        src={engineIcon}
        style={{
          width: 60,
          height: 60,
          backgroundColor: "#0074D9",
          padding: 6,
          borderRadius: 100,
          marginRight: 20,
          marginTop: 8,
        }}
      />
      <p>
        The engine was all the code responsible for making Clarity Coach
        function. It involved the processes for downloading and analysing
        matches and displaying this data to the user.
      </p>
    </div>
    <ul>
      <li>Front end code and styles.</li>
      <li>A GraphQL type system and backend server.</li>
      <li>
        A backend system to periodically query the Dota 2 API for new matches,
        download and parse the found matches, analysing the replay data, and
        alterting users when this process was complete. This system has to be
        error tolerant throughout the entire process.
      </li>
      <li>
        A great development experience. This includes a webpack build system
        that supports hot reloading, and a simple and reliable deployment
        process with integrated staged testing.
      </li>
      <li>
        AWS architecture and deployment. Clarity Coach uses many AWS services,
        including DynamoDB, Lambda, ElastiCache, Step Functions, and S3.
      </li>
    </ul>

    <h4
      style={{
        borderBottom: "4px solid #FF851B",
        paddingBottom: 2,
        display: "inline-block",
      }}
    >
      The content - Machine Learning and Content Creation
    </h4>
    <div style={{ display: "flex" }}>
      <img
        src={bookIcon}
        style={{
          width: 60,
          height: 60,
          backgroundColor: "#FF851B",
          margin: 6,
          borderRadius: 100,
          marginRight: 20,
          marginTop: -4,
        }}
      />
      <p>
        The content is all the writing and data visualisation techniques which
        are used to display content from the engine to the user.
      </p>
    </div>
    <ul>
      <li>
        High quality, hand written content for common problems players are
        facing.
      </li>
      <li>
        Selection of the best data visualisation techniques for displaying the
        extracted data.
      </li>
      <li>
        Association mining and RNNs for predicting hero combinations and
        predicted heroes and bans.
      </li>
    </ul>

    <h4
      style={{
        borderBottom: "4px solid #2ECC40",
        paddingBottom: 2,
        display: "inline-block",
      }}
    >
      The funnel - Scaling technology and business
    </h4>
    <div style={{ display: "flex" }}>
      <img
        src={funnelIcon}
        style={{
          width: 60,
          height: 60,
          backgroundColor: "#2ECC40",
          paddingTop: 6,
          borderRadius: 100,
          marginRight: 20,
          marginTop: 8,
        }}
      />
      <p>
        The funnel is made up of the business practices and continuous iteration
        of getting the right users to the app, to subscribe to the service, and
        to stay happy while using it.
      </p>
    </div>
    <ul>
      <li>Social Media accounts and content creation.</li>
      <li>Targeted ad campaigns and optimisations.</li>
      <li>
        User tracking and analytics, primarily using MixPanel and Google
        Analytics.
      </li>
      <li>A / B testing to optimise landing pages and conversions.</li>
    </ul>

    <Link to="/portfolio/clarity/tech/">Next</Link>
  </Scaffold>
);

export default Clarity;
